export default {
  proxy_path: "https://f0g5wxup6h.execute-api.us-east-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://pwc8879u0f.execute-api.us-east-2.amazonaws.com/api",
  google_api_key: "AIzaSyAVCuQtOaN6jrjHl89-Gi6FS2MKXHMOIwE",
  main_company_term_id: "6",
  crm_base_url: "vale.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.vale.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.vale.forwoodsafety.com",
    APP_CLIENT_ID: "avm6c5u72ofmk33su0sp5djrm",
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://w747t0eyb0.execute-api.us-east-2.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.vale.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
